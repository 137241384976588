const menu = [
  {name: "禾泰故事", url: "story"},
  {name: "禾泰理念", url: "ethos"},
  {name: "禾泰服务", url: "service"},
  {
    name: "禾泰 X 易元堂", url: "withyyt", children: [
      {name: "艺术管理", url: "art"},
      {name: '汽车管理', url: "car"}
    ]
  },
]

export default menu
