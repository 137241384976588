export const PageInfo = [
  {
    "type": "directory", "name": "禾泰x易元堂", "contents": [
      {
        "type": "directory", "name": "汽车管理", "contents": [
          {"type": "file", "name": "2024_April.033.jpeg"},
          {"type": "file", "name": "2024_April.034.jpeg"},
          {"type": "file", "name": "2024_April.035.jpeg"},
          {"type": "file", "name": "2024_April.036.jpeg"},
          {"type": "file", "name": "2024_April.037.jpeg"},
          {"type": "file", "name": "2024_April.038.jpeg"}
        ]
      },
      {
        "type": "directory", "name": "艺术管理", "contents": [
          {"type": "file", "name": "2024_April.020.jpeg"},
          {"type": "file", "name": "2024_April.021.jpeg"},
          {"type": "file", "name": "2024_April.022.jpeg"},
          {"type": "file", "name": "2024_April.023.jpeg"},
          {"type": "file", "name": "2024_April.024.jpeg"},
          {"type": "file", "name": "2024_April.025.jpeg"},
          {"type": "file", "name": "2024_April.026.jpeg"},
          {"type": "file", "name": "2024_April.027.jpeg"},
          {"type": "file", "name": "2024_April.028.jpeg"},
          {"type": "file", "name": "2024_April.029.jpeg"},
          {"type": "file", "name": "2024_April.030.jpeg"},
          {"type": "file", "name": "2024_April.031.jpeg"},
          {"type": "file", "name": "2024_April.032.jpeg"}
        ]
      }
    ]
  },
  {
    "type": "directory", "name": "禾泰故事", "contents": [
      {"type": "file", "name": "2024_April.005.jpeg"},
      {"type": "file", "name": "2024_April.007.jpeg"}
    ]
  },
  {
    "type": "directory", "name": "禾泰服务", "contents": [
      {"type": "file", "name": "2024_April.006.jpeg"},
      {"type": "file", "name": "2024_April.009.jpeg"},
      {"type": "file", "name": "2024_April.010.jpeg"}
    ]
  },
  {
    "type": "directory", "name": "禾泰理念", "contents": [
      {"type": "file", "name": "2024_April.012.jpeg"},
      {"type": "file", "name": "2024_April.013.jpeg"},
      {"type": "file", "name": "2024_April.014.jpeg"},
      {"type": "file", "name": "2024_April.015.jpeg"},
      {"type": "file", "name": "2024_April.016.jpeg"}
    ]
  }
];

let menu = [];

/**
 * @param {string} itemName
 * @param data
 * @param prefix the prefix of found item
 * @return {Object} {path:'/path/of/the/item', content:[xxx]}
 */
export function getContents(itemName, data = PageInfo, prefix = "") {
  for (let datum of data) {
    if (datum.type === 'file') return [];
    let nextType = datum.contents[0].type;
    if (nextType === 'directory') {
      let result = getContents(itemName, datum.contents, "/" + datum.name);
      if (result.path !== '/') return result;
    }
    if (datum.name === itemName) {
      return {path: prefix + "/" + datum.name, contents: datum.contents};
    }
  }

  return {path: '/', contents: []};
}
