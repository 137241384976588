import React, {lazy} from 'react';
import {Route, Routes} from 'react-router';
import MainContent from "@/pages/MainContent";
import menu from "@/menu";
import Home from "@/pages/Home";

// const Home = lazy(() => import('@/pages/Home'))

let routeInfo = [
  {path: '/', element: <Home/>},
]

function initializeRouteInfo(route, prefix) {
  if ((route || []).length === 0) return
  route.forEach(e => {
    let path = prefix + e.url;
    routeInfo.push({path, element: <MainContent menu={e.name}/>})
    initializeRouteInfo(e.children, path + '/')
  })
}

initializeRouteInfo(menu, "/")

function getRouteObject(route) {
  if (!route || route.length === 0) return;
  return route.map(e => {
    return <Route path={e.path} key={e.path}
                  element={<React.Suspense fallback={<p>Loading...</p>}>{e.element}</React.Suspense>}>
      {getRouteObject(route.children)}
    </Route>
  })
}

export default function RouterRoot() {
  return <Routes>{getRouteObject(routeInfo)}</Routes>
}
