import React from 'react'
import '@/styles/MainContent.scss'
import {showHeader, showNav} from "@/lib/redux";
import {connect} from "react-redux";
import {debounce} from "@/lib/util";
import {getContents, PageInfo} from "@/data";

class MainContent extends React.Component {
  previousY = 10000000;
  hideNavTimer;
  scrollListener = e => {
    let headerHeight = 80;
    const {scrollY} = window;
    const {show: {header: propsShow, nav: navShow}} = this.props;
    let hide = this.previousY < scrollY && scrollY > headerHeight;
    if (navShow && !this.hideNavTimer) {
      this.hideNavTimer = setTimeout(() => {
        this.hideNavTimer = undefined;
        this.props.dispatch(showNav(false));
      }, 200)
    }
    if (scrollY < 100 && !propsShow) {
      this.showHeader_(true);
      return;
    }
    if (Math.abs(this.previousY - scrollY) === headerHeight) return;
    this.previousY = scrollY;
    if (propsShow == !hide) return
    if (!hide) {
      this.showHeader_(true);
    } else {
      this.showHeaderDebounce(false);
    }
  };

  componentDidMount() {
    this.showHeaderDebounce = debounce(this.showHeader_, 20)
    window.addEventListener("scroll", this.scrollListener)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollListener)
  }

  showHeader_ = (show) => {
    this.props.dispatch(showHeader(show))
  }

  render() {
    let {menu} = this.props;
    let {path, contents} = getContents(menu)
    return <div id='mainContent' className={`content-size-${contents.length}`}>
      {contents.map(img => <img key={img.name} src={`/res/pictures${path}/${img.name}`} alt={menu}
                                loading='lazy'/>)}
    </div>
  }
}

export default connect(state => ({
  show: state.layout.show,
}))(MainContent)
