// import { Toast } from "antd-mobile";

export function debounce(fn, wait = 200) {
  let timer
  return function () {
    let context = this
    let args = arguments
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(context, args)
    }, wait)
  }
}

export function copyToClipboard(t) {
  // 如果当前浏览器版本不兼容navigator.clipboard
  if (!navigator.clipboard) {
    var ele = document.createElement("input");
    ele.value = t;
    document.body.appendChild(ele);
    ele.select();
    document.execCommand("copy");
    document.body.removeChild(ele);
    if (document.execCommand("copy")) {
      console.log("复制成功！");
    } else {
      console.log("复制失败！");
    }
  } else {
    navigator.clipboard.writeText(t).then(function () {
      console.log("复制成功！");
    }).catch(function () {
      console.log("复制失败！");
    })
  }
}

export function format(date, fmt) {
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "H+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    "S": date.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}
